.MHome{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 71px 0 56px 0;
}
.MHome__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.MHome__bannerBox{
    width: 100%;
    min-height: 160px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MHome-bannerBox__info{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
}
.MHome-bannerBox__infoButton{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #E52EC7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MHome-bannerBox__buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 10px;
}
.MHome-bannerBox__button{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 5px;
    width: 255px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.MHome-bannerBox__button:first-child{
    margin-bottom: 10px;
}
.MHome__links{
    width: 100%;
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0;
    overflow-y: scroll;
}
.MHome__linksWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MCurrency{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MCurrency__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.MCurrency__bannerBox{
    width: 100%;
    height: 177px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MCurrency__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MCurrency__links{
    width: 100%;
    height: calc(100% - 177px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MCurrency__linksWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MCurrency-linkBox__link{
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
}
.MCurrency-link__imgBox{
    margin-right: 20px;
}
.MCurrency-link__imgBox img{
    width: 50px;
    height: 30px;
}
.MCurrency-link__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #000000;
}
.MCurrency-link__currencyBox{
    position: absolute;
    right: 15px;
}
.MCurrency-link__currencyBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
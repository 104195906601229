.MArrangeDelivery{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MArrangeDelivery__mainWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MArrangeDelivery__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MArrangeDelivery__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-top: 40px;
}
.MArrangeDelivery__mainContainer{
    width: 100%;
    min-height: calc(100vh - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 20px;
}
.MArrangeDelivery__orders{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MArrangeDelivery-orders__titleBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid  #D9D9D9;
    margin-top: 40px;
    margin-bottom: 14px;
}
.MArrangeDelivery-orders__titleBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
}
.MArrangeDelivery-orders__titleBox span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    letter-spacing: 0.15px;
    color: #e52ec7;
    cursor: pointer;
}

.MArrangeDelivery-orders__orderBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
}
.MArrangeDelivery-orderBox__textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.MArrangeDelivery-orderBox__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 5px;
}
.MArrangeDelivery-orderBox__textBox div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.MArrangeDelivery-orderBox__textBox span:first-child{
    margin-right: 21px;
}
.MArrangeDelivery-orderBox__textBox span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MArrangeDelivery-orderBox__checkBox{
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid #E52EC7;
}
.MArrangeDelivery-orderBox__checkBoxImg{
    display: none;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/Group 1354.svg");
    background-size: cover;
    background-position: center;
}
.MArrangeDelivery-orders__orderBoxSelected .MArrangeDelivery-orderBox__checkBox{
    border: none;
}
.MArrangeDelivery-orders__orderBoxSelected .MArrangeDelivery-orderBox__checkBoxImg{
    display: flex;
}
.MArrangeDelivery__submitBox{
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F8FA;
    box-shadow: -4px -4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
    z-index: 10;
}
.MArrangeDelivery__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    width: 255px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px !important;
    text-align: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: #FFFFFF !important;
}
.MAuthModal{
    width: 100%;
    height: 100vh;
}
.MAuthPage{
    background-image: url("../../assets/images/Frame 1.png");
    background-position: top;
    background-size: cover;
}
.MAuthModal__mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.MAuthModal__imgBox{
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.MAvatar{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    margin-bottom: 14px;
}
.MAuthModal-form__title{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
}
.MAuthModal__form{
    width: 100%;
    height: calc(100% - 160px);
    padding: 38px 38px 60px 38px;
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    overflow-y: scroll;
}
.MAuthModal-form__subLinks{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
}
.MAuthModal-form__subLinks p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #48A5D1;
}
.MAuthModal-form__subLinks p:last-child{
    text-align: end;
}
.MAuthModal-form__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 8px;
    margin-top: 16px;
    text-transform: uppercase;
}



.MRegisterModal{
    width: 100%;
    height: 100vh;
}
.MRegisterModal__mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.MRegisterModal__form{
    width: 100%;
    height: calc(100% - 160px);
    padding: 38px 38px 60px 38px;
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    overflow-y: scroll;
}
.MRegisterModal-form__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 8px;
    margin-top: 16px;
    text-transform: uppercase;
}


.MRecoverModal{
    width: 100%;
    height: 100vh;
}
.MRecoverModal__mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.MRecoverModal__form{
    width: 100%;
    height: calc(100% - 160px);
    padding: 38px 38px 60px 38px;
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
}
.MRegisterModal-form__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 8px;
    margin-top: 16px;
    text-transform: uppercase;
}
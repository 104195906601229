.MLayoutWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: url("../../assets/images/Frame 1.png");
    background-position: top;
    background-size: cover;
    position: relative;
}
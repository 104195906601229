.MMyOrdersList{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MMyOrdersList__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrdersList__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MMyOrdersList__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MMyOrdersList__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MMyOrdersList__mainContainerWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrdersList-order__iconBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 11px;
    margin-bottom: 11px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.36);
}
.MMyOrdersList-order__iconWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.MMyOrdersList-order__icon{
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid #E52EC7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
}
.MMyOrdersList-order__icon .MFooter-link__icon{
    font-size: 15px;
}
.MMyOrdersList-order__text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.MMyOrdersList-order__text p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 5px;
}
.MMyOrdersList-order__text span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MMyOrdersList-order__flagImg{
    width: 28px;
    height: 16px;
}
.MMyOrdersList-order__dateBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MMyOrdersList-order__dateBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MMyOrdersList-order__dateBox span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #48A5D1;
}



.MMyOrdersList__orderInfoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrdersList-orderInfoBox__infoItem{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}
.MMyOrdersList-orderInfoBox__infoItem p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #48A5D1;
    margin-bottom: 5px;
}
.MMyOrdersList-orderInfoBox__infoItem span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.title p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #48A5D1;
    margin-bottom: 8px;
}

.MMyOrdersList__orderProducts{
    width: 100%;
    display: flex;
}
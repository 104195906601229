.MMyOrdersList{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MMyOrdersList__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrdersList__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MMyOrdersList__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MMyOrdersList__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MMyOrdersList__mainContainerWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.MMyOrdersList__orderInfoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrdersList-orderInfoBox__infoItem{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}
.MMyOrdersList-orderInfoBox__infoItem p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #48A5D1;
    margin-bottom: 5px;
}
.MMyOrdersList-orderInfoBox__infoItem span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.MMyOrdersList-orderInfoBox__infoItem a{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.authPage{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.authModal{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-image: url("../../assets/images/Cover_2  1.png");
}
.authModal__mainContainer{
    height: 100%;
    padding: 0 62px;
    display: flex;
    align-items: center;
    background: transparent;
}
.authModal__form{
    background: #FFFFFF;
    border-radius: 10px;
    width: 608px;
    height: 471px;
    padding: 0 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.authModal-form__title{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 7px;
}
.Avatar{
    background: #48A5D1 !important;
    margin-bottom: 14px;
}
.authModal-form__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF !important;
    cursor: pointer;
    margin-bottom: 8px !important;
    margin-top: 10px !important;
    text-transform: uppercase;
}
.authModal-form__subLinks{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.authModal-form__subLinks p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.16px;
    color: #48A5D1;
}
.authModal-form__subLinks span{
    font-weight: 600;
    margin-left: 5px;
}
.registerModal-from__codeRepeatLink{
    font-weight: 600;
    margin-left: 5px;
}

/*регистрация*/

.registerModal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.registerModal__mainContainer{
    height: 100%;
    padding: 0 62px;
    display: flex;
    align-items: center;
    background: #F5F5F5;
}
.registerModal__imgContainer{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/images/Cover_3 1 (1).png");
}
.registerModal__form{
    background: #FFFFFF;
    border-radius: 10px;
    width: 490px;
    min-height: 531px;
    padding:30px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.registerModal-form__submitButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #FFFFFF !important;
    cursor: pointer;
    margin-top: 36px !important;
    margin-bottom: 8px !important;
    text-transform: uppercase;
}
.registerModal-form__photoAdInput{
    margin-top: 16px;
    margin-bottom: 8px;
    height: 56px !important;
    justify-content: space-between;
    text-transform: none;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16.5px 14px;
}
.goBackItem{
    position: absolute !important;
    top: 17px !important;
    left: 17px !important;
}
.goBackItem span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #48A5D1;
}

/*recover*/

.recoverModal{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-image: url("../../assets/images/Cover_2  1.png");
}
.recoverModal__mainContainer{
    height: 100%;
    padding: 148px 62px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recoverModal__form{
    background: #FFFFFF;
    border-radius: 10px;
    width: 490px;
    height: 531px;
    padding:0 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
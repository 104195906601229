.MTransactions{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MTransactions__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MTransactions__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MTransactions__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MTransactions__bannerButtons{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.MTransactions__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0 50px 0;
    overflow-y: scroll;
}
.MTransactions__mainContainerWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MTransactions__bannerBalance{
    border-radius: 5px;
    border: 1px solid #E52EC7;
    padding: 7px 13px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MTransactions__transaction{
    width: 100%;
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #48A5D1;
}
.MTransactions-transaction__title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.MTransactions-transaction__title p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 5px;
}
.MTransactions-transaction__title span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MTransactions-transaction__price{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.MTransactions-transaction__price p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FF0000;
    margin-bottom: 5px;
}
.MTransactions-transaction__price span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #B9B9B9;
}
.orderView__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.orderView__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
    width: 550px;
    margin-bottom: 28px;
}
.orderView__mainContainer{
    width: 100%;
    padding: 32px 0 50px 0;
    border-radius: 10px;
    background: transparent;
}
.orderView__header{
    width: 100%;
    border-bottom: 1px solid #48A5D1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.orderView__header p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 12px 38px;
    background: rgba(72, 165, 209, 0.4);
    border-radius: 10px 10px 0 0;
}
.orderView__body{
    width: 100%;
    padding: 20px 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
}
.orderView__orderInfoBox{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 40px;
}
.orderView-orderInfoBox__infoItem{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.orderInfoBox-infoItem__title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    min-width: 150px;
    margin-right: 30px;
}
.orderInfoBox-infoItem__text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.orderView__footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 38px;
    background: rgba(72, 165, 209, 0.4);
}
.orderView-orderFooter__infoItem{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.orderInfoBox-footer__title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-right: 30px;
}
.orderInfoBox-footer__text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}
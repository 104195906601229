.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
}
.footer__upperContainer{
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: flex-end;
}
.footer__upperContainerWrapper{
    width: 100%;
    max-width: 1180px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 50px 0;
}
.footer__logoBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer__logo{
    margin-bottom: 18px;
}
.footer__logoText{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    width: 430px;
}


.footer__contactBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer__contactTitle{
    height: 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-bottom: 18px;
}
.footer__contactLink{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 8px;
}


.footer__scheduleBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer__scheduleTitle{
    height: 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-bottom: 18px;
}
.footer__scheduleText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
}


.footer__socialMediaBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
}
.footer__socialMediaLink{
    margin-bottom: 20px;
}



.footer__subContainer{
    width: 100%;
    height: 50px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    gap: 30px;
}
.MNotificationsView{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MNotificationsView__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MNotificationsView__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MNotificationsView__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MNotificationsView__bannerButtons{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.MNotificationsView__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.MNotificationsView__mainContainerWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 20px;
}
.MNotificationsView__title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #48A5D1;
}
.MNotificationsView__title p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: start;
    letter-spacing: -0.02em;
    color: #000000;
    margin-right: 20px;
}
.MNotificationsView__title span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MNotificationsView__text{
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: start;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
}
.MAddresses{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MAddresses__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MAddresses__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MAddresses__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MAddresses__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MAddresses__mainContainerWrapper{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.MAddresses__link{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
}
.MAddresses-link__imgBox{
    margin-right: 20px;
}
.MAddresses-link__imgBox img{
    width: 50px;
    height: 30px;
}
.MAddresses-link__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #000000;
}
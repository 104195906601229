.MMyOrders{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MMyOrders__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrders__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MMyOrders__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MMyOrders__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MMyOrders__mainContainerWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MMyOrders-linkBox__link{
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 9px;
    position: relative;
}
.MMyOrders-linkBox__link__delivery{
    justify-content: space-between;
}

.MMyOrders-link__iconBox{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #E52EC7;
    border-radius: 50%;
    margin-right: 28px;
}
.MMyOrders-link__icon{
    color: white;
}
.MMyOrders-link__textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.MMyOrders-link__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: start;
    margin-bottom: 3px;
}
.MMyOrders-link__textBox span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
    text-align: start;
}
.agreement{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;
}
.agreement_mainBox{
    width: 1300px;
    padding: 40px;
}
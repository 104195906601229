.header *{
    transition-duration: .2s;
}
.header{
    width: 100%;
    height: 70px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    /*box-shadow: 4px 4px 4px rgba(25, 118, 210, 0.1);*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    -webkit-box-shadow: 43px 25px 74px -17px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 43px 25px 74px -17px rgba(34, 60, 80, 0.2);
    box-shadow: 43px 25px 74px -17px rgba(34, 60, 80, 0.2);
}
.header__mainWrapper{
    width: 1300px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__logo{
    margin-right: auto;
}
.header__navLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 2px solid #D9D9D9;
    height: 100%;
    white-space: nowrap;
    width: 563px;
    padding-right: 40px;
}
.header__navLink{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-decoration: none;
    padding: 2px;
    cursor: pointer;
}
.header__navLink:hover{
    font-weight: 700;
    border-bottom: 1px solid white;
}
.header__navLinkSelected{
    font-weight: 700;
    border-bottom: 1px solid white;
}
.header__exchangeText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.header__exchangeText p{
    white-space: nowrap;
}
.header__exchangeText span{
    color: white;
}
.header__scoreText{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    height: 100%;
    background: #EF5DA8;
    border-radius: 5px;
    padding: 3px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}
.header__scoreText span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.17px;
    color: #FFFFFF;
}
.header__notificationIcon{
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header__avatarBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-avatarBox__text{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 20px;
    height: 45px;
}
.header-avatarBox__text p{
    align-self: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    width: 200px;
    white-space: nowrap;
    overflow-x: scroll;
}
.header-avatarBox__text p::-webkit-scrollbar {
    width: 0;
}
/* хром, сафари */
.header-avatarBox__text p::-webkit-scrollbar { width: 0; }

/* ie 10+ */
.header-avatarBox__text p { -ms-overflow-style: none; }

.header-avatarBox__text span{
    position: absolute;
    bottom: 0;
    left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    text-align: left;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    opacity: 0.6;
    white-space: nowrap;
}



.header__sideBarOpened{
    position: fixed;
    left: 0;
    top: 70px;
    width: 217px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0;
    overflow: hidden;
    -webkit-box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);
    box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);

}
.header-sideBarLink{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
}
.header-sideBarLink .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLink .header-sideBarLinkIcon{
    color: #B9B9B9;
}

.header-sideBarLinkSelected{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    background: rgba(72, 165, 209, 0.3);
    border-right: 4px solid #48A5D1;
}
.header-sideBarLinkSelected .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLinkSelected .header-sideBarLinkIcon{
    color: #48A5D1;
}



.header-sideBarLink:hover{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    background: rgba(72, 165, 209, 0.3);
    border-right: 4px solid #48A5D1;
}
.header-sideBarLink:hover .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLink:hover .header-sideBarLinkIcon{
    color: #48A5D1;
}


.header__sideBarClosed{
    position: fixed;
    left: 0;
    top: 70px;
    width: 80px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0;
    overflow: hidden;
    -webkit-box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);
    box-shadow: 43px 25px 100px -29px rgba(34, 60, 80, 0.2);
}

.header__sideBarClosed .header-sideBarLink{
    padding-left: 27px;
}
.header__sideBarClosed .header-sideBarLinkSelected{
    padding-left: 27px;
}
.header__sideBarClosed .header-sideBarLinkText{
    display: none;
}


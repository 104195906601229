.MPersonal{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MPersonal__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MPersonal__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MPersonal__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MPersonal__bannerButtons{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.MPersonal__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MPersonal__mainContainerWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MPersonal-edit__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 11px;
    margin-bottom: 26px;
    border-bottom: 2px solid #D9D9D9;
}
.MPersonal-edit__headerText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.MPersonal-edit__headerText div{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}
.MPersonal-edit__headerText p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
}
.MPersonal-edit__headerText span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    text-align: right;
    letter-spacing: 0.17px;
    color: #48A5D1;
}
.MPersonal-edit__headerButton{
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 50%;
    cursor: pointer;
}
.MPersonal-edit__body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.MPersonal-edit__bodyProperty{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}
.MPersonal-edit__bodyProperty p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
    margin-bottom: 10px;
}
.MPersonal-edit__bodyProperty span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
}

.MPersonal .personalPage-passportInfo__editBox{
    margin-top: 30px;
}
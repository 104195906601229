.MHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.41);
}
.MHeader__mainWrapper{
    width: 90%;
    height: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MHeader__avatarBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.MHeader-avatarBox__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    margin-left: 10px;
}
.MHeader-avatarBox__text p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MHeader-avatarBox__text span{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.6);
}
.personalPage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.personalPage__mainWrapper{
    width: 1300px;
    padding: 40px 0 40px 120px;
}
.componentMainWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.personalPage__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.personalPage__mainContainer{
    width: 100%;
    border-radius: 10px;
    display: grid;
    grid-template-columns: calc(60% - 10px) calc(40% - 10px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.personalPage__userInfo{
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.personalPage-userInfo__header{
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 2px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.userInfo-header__avatarBox{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.userInfo-header__avatarBoxTitle{
    margin-left: 20px;
}
.userInfo-header__avatarBoxTitle h1{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}
.userInfo-header__avatarBoxTitle div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.userInfo-header__avatarBoxTitle p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.17px;
    color: #48A5D1;
}
.userInfo-header__avatarBoxTitle span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
    margin-right: 20px;
}
.personalPage-userInfo__body{
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.userInfo-body__items{
    display: flex;
    margin-bottom: 20px;
}
.userInfo-body__items p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0.15px;
    color: #48A5D1;
    width: 90px;
}
.userInfo-body__items span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.15px;
    color: #000000;
}
.personalPage__balanceInfo{
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.personalPage-balanceInfo__balanceText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.personalPage-balanceInfo__balanceText h1{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 14px;
}
.personalPage-balanceInfo__balanceText p{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.15px;
    color: #48A5D1;
    justify-content: flex-start;
}
.personalPage-balanceInfo__balanceText span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #48A5D1;
    margin-left: 12px;
}
.personalPage-balanceInfo__balanceButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding-left: 42px !important;
    padding-right: 42px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #FFFFFF !important;
}
.personalPage__passportInfo{
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.personalPage-passportInfo__title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.personalPage-passportInfo__title h1{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}
.personalPage-passportInfo__images{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.passportInfo-images__imgBox{
    width: 100%;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    cursor: pointer;
}
.passportInfo-images__imgBoxTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
}
.passportInfo-images__imgBoxSubTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    border-bottom: 1px solid #48A5D1;
    color: #48A5D1;
}
.personalPage-passportInfo__imagesNotVerified{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    padding: 20px 0;
}
.personalPage-passportInfo__imagesNotVerified p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 4px;
}
.personalPage-passportInfo__imagesNotVerified span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.16px;
    color: #B9B9B9;
}


.dropPhotoBox__container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}
.dropPhotoBox__container input{
    position: absolute;
    display: flex;
    width: 1px;
    height: 1px;
    border: none;
    background: transparent;
    top: 0;
    left: 0;
}
.dropPhotoBox__container label{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dropPhotoBox__container label span{
    text-align: center;
    letter-spacing: 0.15px;
    color: #48A5D1;
    cursor: pointer;
    border-bottom: 1px solid #48A5D1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
.dropPhotoBox__containerTitle{
    position: absolute;
    top: -19px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
}

.personalPage-passportInfo__editBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.personalPage-passportInfo__editBoxNoteText{
    width: 100%;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.36);
    margin-top: 4px;
    margin-bottom: 20px;
}
.passportDialogContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 20px;
}
.passportDialogContainer h1{
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: start;
    margin-bottom: 17px;
}
.passportDialogContainer img{
    width: 100%;
    margin-bottom: 10px;
}
.passportDialogContainer div{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

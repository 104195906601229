.orderAdd__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.orderAdd__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
    width: 550px;
    margin-bottom: 28px;
}
.orderAdd__mainContainer{
    width: 100%;
    background: white;
    padding: 32px 0 50px 0;
    border-radius: 10px;
}
.orderAdd-mainContainer__header{
    width: 100%;
    padding: 0 44px 14px 44px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
}
.orderAdd-mainContainer__headerText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
}
.orderAdd-mainContainer__body{
    width: 100%;
    padding: 40px 44px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.orderAdd-mainContainer__bodySelectBox{
    width: 100%;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.orderAdd-mainContainer__bodyAdditionalService{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.orderAdd-mainContainer__footer{
    width: 100%;
    padding: 40px 0 0 0;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderAdd-mainContainer__footerPackageAddButton{
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #48A5D1;
    text-transform: none !important;
    margin-bottom: 35px !important;
}
.userAccountDeleteButton{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #48A5D1;
    text-transform: none !important;
}
.orderAdd-mainContainer__bodyOrders{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderAdd-bodyOrders__orderBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.orderAdd-orderBox__header{
    width: 100%;
    height: 50px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(72, 165, 209, 0.7);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
}
.orderAdd-orderBox__headerText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.orderAdd-orderBox__body{
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderAdd__packageProperties{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.TextField-without-border-radius{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Select-without-border-radius{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.MAddressesView{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MAddressesView__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MAddressesView__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MAddressesView__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MAddressesView__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    position: relative;
}
.MAddressesView__mainContainerWrapper{
    width: 100%;
    display: flex;
    background: #F6F8FA;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 20px;
}

.MAddressesView__flagBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #48A5D1;
    margin-bottom: 20px;
}
.MAddressesView-flagBox__clientCode{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.MAddressesView-flagBox__flagImg{
    width: 70px;
    height: 40px;
    margin-right: 18px;
}
.MAddressesView-flagBox__clientCode p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #E52EC7;

}
.MAddressesView-flagBox__clientCode span{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #48a5d1;
}
.MAddressesView-flagBox__note{
    background: rgba(72, 165, 209, 0.2);
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
}
.MAddressesView-flagBox__note p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.MAddressesView-flagBox__deliveryBox{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}
.MAddressesView-flagBox__deliveryBox div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.MAddressesView-flagBox__deliveryBox p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
}
.MAddressesView-flagBox__deliveryBox span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
}
.MAddressesView-flagBox__deliveryBox div:first-child {
    margin-right: 42px;
}

.MAddressesView__infoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.MAddressesView-infoBox__textFields{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}
.MAddressesView-infoBox__textField{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 10px;
}
.MAddressesView-infoBox__textField p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
}
.MAddressesView-infoBox__textField input{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    background: none;
    width: 100%;
    padding-right: 40px;
    text-overflow: ellipsis;
}
.MAddressesView-infoBox__textField button{
    position: absolute;
    bottom: 0;
    right: 0;
    background: none;
    cursor: pointer;
}

.MAddressesView-infoBox__buttons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MAddressesView-infoBox__buttons button{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 5px;
    width: 167px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.MAddressesView__noteBox{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    opacity: 0.8;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
}
.MAddressesView__transportButtons{
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.MAddressesView__transportButtons::-webkit-scrollbar {
    width: 0;
}
.MNotifications{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MNotifications__mainWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MNotifications__bannerBox{
    width: 100%;
    height: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MNotifications__bannerText{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.MNotifications__bannerButtons{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.MNotifications__bannerButton{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    border-radius: 5px;
    border: 2px solid #E52EC7;
}
.MNotifications__bannerButtonSelected{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
}
.MNotifications__mainContainer{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0;
    overflow-y: scroll;
}
.MNotifications__mainContainerWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MNotifications__link{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 9px;
    position: relative;
}
.MNotifications__iconBox{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(97.04deg, #48A5D1 -45.3%, #E52EC7 187.93%);
    border-radius: 50%;
    margin-right: 28px;
}
.MNotifications__icon{
    color: white;
}
.MNotifications__textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 30px;
}
.MNotifications__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: start;
    margin-bottom: 3px;
}
.MNotifications__textBox span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
    text-align: start;
}
.MNotifications__arrowBox{
    position: absolute;
    right: 15px;
}
.MNotifications__arrow{
    color: #48A5D1;
}
.addressPage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.addressPage__mainWrapper{
    width: 1300px;
    padding: 40px 0 40px 120px;
}
.addressPage__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.addressPage__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #E52EC7;
    width: 550px;
    margin-bottom: 28px;
}
.addressPage__mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addressPage__buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #48A5D1;
}
.addressPage__countryButtons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addressPage__countryButton{
    min-width: 128px;
    padding: 0 10px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    cursor: pointer;
}
.countryButtonSelected{
    color: #FFFFFF;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    z-index: 99 !important;
}
.addressPage__transportButtons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addressPage__transportButton{
    padding: 0 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}
.addressPage__transportButtonIcon{
    display: flex;
}
.addressPage__transportButtonText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: none;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.transportButtonSelected{
    z-index: 99 !important;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
}
.transportButtonSelected .addressPage__transportButtonIcon{
    display: none;
}
.transportButtonSelected .addressPage__transportButtonText{
    display: flex;
}
.addressPage__infoContainer{
    z-index: 15;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 60px 20px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.addressPage-infoContainer__flagBox{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 17px;
    border-bottom: 2px solid #D9D9D9;
}
.infoContainer-flagBox__flagImg{
    margin-right: 20px;
}
.infoContainer-flagBox__clientCode{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 30px;
}
.infoContainer-flagBox__clientCode p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #E52EC7;
}
.infoContainer-flagBox__clientCode span{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.infoContainer-flagBox__note{
    background: rgba(72, 165, 209, 0.2);
    border-radius: 6px;
    padding: 10px;
    max-width: 394px;
    margin-right: 67px;
}
.infoContainer-flagBox__note p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.infoContainer-flagBox__deliveryBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.infoContainer-flagBox__deliveryBox div{
    display: flex;
}
.infoContainer-flagBox__deliveryBox p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
    min-width: 78px;
    margin-right: 20px;
    text-align: start;
}
.infoContainer-flagBox__deliveryBox span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: start;
}


.addressPage-infoContainer__receiverBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 20px 0;
}
.infoContainer-receiverBox__infoBox{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.receiverBox-infoBox__textFields{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}
.receiverBox-infoBox__textField{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}
.receiverBox-infoBox__textField p{
    width: 160px;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    margin-right: 40px;
    white-space: nowrap;
}
.receiverBox-infoBox__textField input{
    width: 221px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    margin-right: 40px;
    text-overflow: ellipsis;
}
.receiverBox-infoBox__textField button{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
    background: transparent;
    cursor: pointer;
    margin-right: 57px;
}

.receiverBox-infoBox__buttons{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.addressPage__copyButton{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #48A5D1;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 36px;
    cursor: pointer;
}
.infoContainer-receiverBox__noteBox{
    width: 100%;
    max-width: 533px;
    flex-shrink: 0;
    padding: 20px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    opacity: 0.8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.receiverBox-noteBox__title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #141414;
    margin-bottom: 20px;
}
.receiverBox-noteBox__text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #141414;
}
.receiverBox-noteBox__text a{
    color: #e52ec7;
    text-decoration: underline;
}

.addressPage-infoContainer__muiTextFieldBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0 0 0;
}
.addressPage-infoContainer__muiTextFields{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 35px;
    grid-column-gap: 50px;
    margin-bottom: 30px;
}
.addressPage-infoContainer__muiTextField{
    width: 100%;
    padding: 15px 10px 15px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 2px solid #48A5D1;
    border-radius: 4px;
}
.infoContainer-muiTextField__title{
    position: absolute;
    top: -19px;
    left: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #48A5D1;
}
.infoContainer-muiTextField__text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.infoContainer-muiTextField__button{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
    background: transparent;
    cursor: pointer;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emptyData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.emptyData p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.3);
}

.mainModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 80px 60px 40px 60px;
  border-radius: 4px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper .css-10hburv-MuiTypography-root {
  display: none !important;
}

.css-yb0lig {
  display: none !important;
}

.MuiPaper-root:hover .css-10hburv-MuiTypography-root {
  display: block !important;
}

.MuiBox-root:hover .css-yb0lig {
  display: block !important;
}

.css-1f8bwsm {
  min-width: 0px !important;
}

.MuiBox-root:hover .css-1f8bwsm {
  min-width: 56px !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper .css-cveggr-MuiListItemIcon-root {
  /* min-width: 56px; */
  min-width: 0px !important;
}

.MuiPaper-root:hover .css-cveggr-MuiListItemIcon-root {
  min-width: 56px !important;
}

.MuiPaper-root:hover .layout {
  padding-left: 224px !important;
}

.MuiPaper-root:hover .MuiList-root {
  gap: 0px !important;
}

.layout {
  padding-left: 50px !important;
}

.MuiList-root {
  display: flex;
  flex-direction: column;
  gap: 6.2px;
}

.mainModalMobile {
  padding: 50px 30px !important;
}

.order-tracking .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 2.4375em;
}

.order-tracking .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  height: 2.4375em !important;
}

.order-tracking .css-qmg3g5-MuiButtonBase-root-MuiButton-root {
  line-height: 3;
}

.shadow-block {
  box-shadow: 4px 4px 4px 0px #0000001a;
}

.bg-gradient {
  background: linear-gradient(100.58deg, #48a5d1 0%, #e52ec7 100%);
}



.MFooter{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 3;
    border-top: 1px solid #48A5D1;
}
.MFooter__bottomContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    background: white;
}
.MFooter__mainWrapper{
    width: 90%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    position: relative;
}
.MFooter__navButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MFooter__navText{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #B9B9B9;
}
.MFooter__navIcon{
    color: #B9B9B9;
}
.MFooter__navButton:hover .MFooter__navText, .MFooter__navButton:hover .MFooter__navIcon{
    color: #48A5D1;
}
.MFooter__navButtonSelected .MFooter__navText, .MFooter__navButtonSelected .MFooter__navIcon{
    color: #48A5D1;
}

.MFooter__orderAddButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MFooter__orderAddIcon{
    padding: 15px;
    position: absolute;
    top: -57px;
    z-index: 4;
}
.MFooter__orderAddIcon div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    border-radius: 50px;
    width: 56px;
    height: 56px;
    color: white;
    font-size: 25px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}
.MFooter__orderAddText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #E52EC7;
}



.MFooter__linkBox{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/Frame 1.png");
    background-position: top;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.MFooter-linkBox__bannerBox{
    width: 100%;
    height: 217px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.MFooter-linkBox__bannerBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MFooter-linkBox__links{
    width: 100%;
    height: calc(100% - 217px);
    background: #F6F8FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    padding: 20px 0 100px 0;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
}
.MFooter-linkBox__links::-webkit-scrollbar {
    width: 0;
}
.goBackPageItem{
    position: absolute !important;
    top: 17px !important;
    left: 17px !important;
    z-index: 5;
}
.goBackPageItem span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.MFooter-linkBox__linksWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.MFooter-linkBox__linksWrapper::-webkit-scrollbar {
    width: 0;
}
.MFooter-linkBox__link{
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 9px;
    position: relative;
}
.MFooter-link__iconBox{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(97.04deg, #48A5D1 -45.3%, #E52EC7 187.93%);
    border-radius: 50%;
    margin-right: 28px;
}
.MFooter-link__icon{
    color: white;
}
.MFooter-link__textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.MFooter-link__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: start;
    margin-bottom: 3px;
}
.MFooter-link__textBox span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
    text-align: start;
}
.MFooter-link__arrowBox{
    position: absolute;
    right: 15px;
}
.MFooter-link__arrow{
    color: #48A5D1;
}
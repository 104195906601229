.transactionsPage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.transactionsPage__mainWrapper{
    width: 1300px;
    padding: 40px 0 40px 120px;
}
.componentMainWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.transactionsPage__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.transactionsPage__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
    width: 550px;
    margin-bottom: 28px;
}
.transactionsPage__mainContainer{
    width: 100%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
}